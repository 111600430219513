export const isIos = () => !!window.webkit?.messageHandlers?.firebase

export const isAndroid = () => !!window.NavigatorWebInterface

export const isNativeApp = () => isIos() || isAndroid()

export enum Platform {
	IOS = 'ios',
	ANDROID = 'android',
	WEB = 'web',
}

export const getPlatform = (): Platform => {
	if (isIos()) {
		return Platform.IOS
	}
	if (isAndroid()) {
		return Platform.ANDROID
	}
	return Platform.WEB
}
